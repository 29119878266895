import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import questionPath from '../lib/question_path';

import '../style/intro.scss';

import Layout from '../layouts/plain';
import SEO from '../components/seo';
import Logo from '../images/logotxt.svg';
import Rectangle from '../components/rectangle';
import Footer from '../components/footer';
import useLanguageStrings from '../hooks/useLanguageStrings';

const queryEN = graphql`
  {
    pageEN: contentfulPage(
      contentful_id: { eq: "5x63wHwcO5YAklOPJwj19F" }
      node_locale: { eq: "en-US" }
    ) {
      lead
      body {
        json
      }
    }
    formEN: contentfulForm(
      contentful_id: { eq: "3cWRuWAFZsyozh9LeTuqcA" }
      node_locale: { eq: "en-US" }
    ) {
      questions {
        question {
          question
        }
      }
    }
    pageDA: contentfulPage(
      contentful_id: { eq: "5x63wHwcO5YAklOPJwj19F" }
      node_locale: { eq: "da" }
    ) {
      lead
      body {
        json
      }
    }
    formDA: contentfulForm(
      contentful_id: { eq: "3cWRuWAFZsyozh9LeTuqcA" }
      node_locale: { eq: "da" }
    ) {
      questions {
        question {
          question
        }
      }
    }
  }
`;

const Start = ({ locale }) => {
  const { pageEN, pageDA, formEN, formDA } = useStaticQuery(queryEN);
  const {
    lead,
    body: { json: body },
  } = locale === 'da' ? pageDA : pageEN;
  const { questions } = locale === 'da' ? formDA : formEN;
  const { start } = useLanguageStrings();

  const goto = questionPath(
    questions[0].question.question,
    locale === 'da' ? '' : '/en',
  );
  const BodyComponent = useMemo(() => documentToReactComponents(body), []);

  return (
    <>
      <SEO description={lead} />
      <Layout theme="theme-black" id="intro">
        <div className="language-switch">
          <Link to="/" className={locale === 'da' ? 'is-active' : ''}>
            DA
          </Link>
          <span>/</span>
          <Link to="/en" className={locale !== 'da' ? 'is-active' : ''}>
            EN
          </Link>
        </div>
        <main>
          <div id="intro-container">
            <div className="logo">
              <Logo />
            </div>
            <Rectangle className="intro-rectangle" />
            <div id="intro-content">
              {BodyComponent}
              <Link to={goto} className="button">
                {start}
              </Link>
            </div>
          </div>
        </main>
        <Footer />
      </Layout>
    </>
  );
};

Start.defaultProps = {
  locale: '',
};

Start.propTypes = {
  locale: PropTypes.string,
};

export default Start;
