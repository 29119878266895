import React from 'react';
import Logo from '../images/logotxt.svg';
import useLanguageStrings from '../hooks/useLanguageStrings';


export default function ByHeadlight() {
  const { by } = useLanguageStrings();
  return (
    <div className="by-headlight">
      <span className="phone-hidden">
        {by}
      </span>
      <a href="https://www.weareheadlight.com/">
        <Logo className="logo" />
      </a>
    </div>
  );
}
