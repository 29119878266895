import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useLocation } from '@reach/router';

import ByHeadlight from './byheadlight';
import useLanguageStrings from '../hooks/useLanguageStrings';

export default function Footer({ currentPath }) {
  const { about } = useLanguageStrings();
  const { pathname } = useLocation();
  const aboutPath = pathname.indexOf('/en') === 0 ? '/en/about/' : 'om';

  return (
    <footer>
      <div className="lower-west">
        <TransitionLink
          to={aboutPath}
          entry={{
            length: 1,
            state: {
              prevPath: currentPath,
            },
          }}
          className="decoration-none"
        >
          {about}
        </TransitionLink>
      </div>
      <div className="lower-east">
        <ByHeadlight />
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  currentPath: undefined,
};

Footer.propTypes = {
  currentPath: PropTypes.string,
};
